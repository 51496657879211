var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDisplayed)?_c('div',{staticClass:"action-button-feedback"},[(_vm.showOverlay && (_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
      && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled) && _vm.authUser)?_c('div',{staticClass:"mobile-overlay"}):_vm._e(),_c('button-component',{attrs:{"id":"feedback-button","disabled":_vm.session && _vm.session.surveyUrl
      ? false
      : (_vm.survey && _vm.surveyAnswered
        && (!_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
          || !_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled)),"rounded":true,"text":_vm.actionButtonText,"size":"md","variant":"secondary mobile-z-index"},on:{"on-click":_vm.onMainButtonClick},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-thumbs-up"}})]},proxy:true}],null,false,4153953514)}),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
      && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled && _vm.authUser)?_c('b-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () {_vm.$emit('hide-popover')}),expression:"() => {$emit('hide-popover')}"}],attrs:{"id":"popover","placement":_vm.getPopoverPlacement,"custom-class":"feedback-drop-menu","target":"feedback-button","triggers":"click"},on:{"hide":function($event){return _vm.$emit('hide-popover')},"show":function($event){return _vm.$emit('show-popover')}}},[_c('div',{staticClass:"d-flex flex-column w-100"},[(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled)?_c('div',{staticClass:"d-flex align-items-center justify-content-between column-gap-4 w-100"},_vm._l((_vm.listIcons),function(item){return _c('div',{key:item[0],staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('button-icon-component',{staticClass:"mx-2 mb-2",attrs:{"variant":_vm.reaction === item.ratingValue ? 'alt-2' : 'tertiary',"shape":"round","size":"lg"},on:{"on-click":function($event){return _vm.chooseAction(item.ratingValue, true)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{class:[
                  'icon-24',
                  _vm.reaction !== item.ratingValue ? 'text-neutral-n-8-dark-ink' : 'text-blue-b-3-primary-blue'
                ],attrs:{"icon":_vm.reaction === item.ratingValue ? ("fa-solid fa-" + (item.icon)) : ("fa-regular fa-" + (item.icon)),"container-classes":"icon-24","icon-classes":"icon-20"}})]},proxy:true}],null,true)}),_c('p',{staticClass:"m-0 paragraph-3 text-neutral-n-6-label text-center"},[_vm._v(" "+_vm._s(_vm.$t(("survey.i-" + (item.key))))+" ")])],1)}),0):_vm._e(),((_vm.survey && _vm.survey.uid) || (_vm.session && _vm.session.surveyUrl))?_c('div',{class:[
          'd-flex flex-column align-items-center justify-content-center',
          {'mt-4': _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled}
        ]},[(_vm.surveyAnswered)?_c('p',{staticClass:"m-0 text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('survey.feedback.title-answered'))+" ")]):_vm._e(),(!_vm.surveyAnswered || (_vm.session && _vm.session.surveyUrl))?_c('p',{staticClass:"m-0 text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('survey.feedback.title'))+" ")]):_vm._e(),(!_vm.surveyAnswered || (_vm.session && _vm.session.surveyUrl))?_c('p',{staticClass:"m-0 paragraph-1 text-blue-b-3-primary-blue font-weight-bold cursor-pointer",on:{"click":function($event){return _vm.onTakeSurveyClick()}}},[_vm._v(" "+_vm._s(_vm.$t('survey.feedback.survey-button'))+" ")]):_vm._e()]):_vm._e()])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }